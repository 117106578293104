import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/LSAT.css';



export default props => {
    return (
        <div className="content lsat">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">
                    
                        <h1 className="sr-only">LSAT (Law School Admissions Test)</h1>
                    <div className="text-blocks"> 
                         <div className="text-block1">
                            <p>The LSAT is the only standardized test designed specifically for law school admission.</p>
                        </div>
                        <div className="text-block2">
                            <p>The LSAT measures the reasoning, reading, and writing skills that are essential for success in law school and the legal profession.</p>
                        </div>
                        <div className="text-block3">
                            <p><span className="stylized">Research consistently shows</span> The LSAT is the best single predictor of law school success &mdash; even better than undergraduate GPA.</p>
                        </div>
                        </div>
                </div>  
            {/* END OF SLIDE 1 */}
                <div className="board board2">
                    
                    <h1 className="sr-only">LSAT (Law School Admissions Test)</h1>
                        <div className="text-block1">
                            <p>As part of a holistic admission process,
                            the LSAT provides a way for schools to
                            identify highly qualified candidates who
                            might otherwise be overlooked on the
                            basis of undergraduate institution, GPA,
                            lack of access to extracurricular activities,
                            or other factors.
                            </p>
                        </div>
                        <div className="text-block2">
                            <p>The LSAT is the most trusted test in law school admission and is the only test accepted by all ABA-accredited law schools.</p>
                        </div>    
                        </div> 
            {/* END OF SLIDE 2 */} 
                      
            </Carousel>
     
         </div>
    );
};