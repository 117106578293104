import React, { useState } from "react";


const FORM_ENDPOINT = "https://prod-177.westus.logic.azure.com:443/workflows/6fad2e99cd47472bb5c422704ed859ef/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=WFBtD7SPSLWNJZ_aPZVXu6-VsuMrRpVYFezKTEe0xxU";

const FeedbackForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
   
    }

    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Form response was not ok');
        }

        setSubmitted(true);
      })
      .catch((err) => {
        // Submit the form manually
        e.target.submit();
      });
  };

  if (submitted) {
    return (
      <>
        <h2>Thank you for sharing your input!</h2>
        <div>Your feedback will help us create better experiences for future events.</div>
      </>
    );
  }

  return (
    <div className="content form">
      <h1>We value your feedback!</h1>                  
      <p> Please use this space to let us know what you thought of your visit to our booth. Your input will help us improve future offerings and experiences for the schools and individuals we serve. </p>
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
      className="feedbackForm"
    > <fieldset>
    <legend>Feedback Subject (select one)</legend>
    <ul className="form-select">
      <li><input type="radio" name="subject" id="event" value="Event" />
      <label htmlFor="event">Event</label>
      </li>
      <li><input type="radio" name="subject" id="general" value="General" />
      <label htmlFor="general">General</label> 
      </li>
      <li><input type="radio" name="subject" id="technical" value="Technical" />
      <label htmlFor="technical">Technical</label> 
      </li>
      <li><input type="radio" name="subject" id="other" value="Other" />
      <label htmlFor="other">Other</label>
      </li>
    </ul>
  </fieldset>
      <div>
        <label htmlFor="message">Comments (required)</label>
        <textarea name="message" type="text" id="message" autoComplete="off" required />
      </div>
      <div>
        <p>You may submit your feedback anonymously, but if you'd like LSAC to contact you about your feedback, please provide your name and email address below.</p>
      </div>
      <div>
        <label htmlFor="name">Preferred Name</label>
        <input type="text" name="name" id="name" autoComplete="name" />
      </div>
      <div>
        <label htmlFor="email">Email Address</label>
        <input type="email" name="email" id="email" autoComplete="email" />
      </div>
      <div>
             
       
      </div>
      
      <div>
        <button type="submit"> Send your message </button>
      </div>
    </form>
    </div>
  );
};

export default FeedbackForm;