import React from 'react';
import Link from "./Link";
<link rel="manifest" href="/manifest.json"></link>


export default props => {
    return (
<div className="home-screen">
      <h1>Welcome!</h1>
      <h2> We're glad you stopped by.</h2>
      <p>LSAC is continually evolving its portfolio of products, services, and solutions to support the needs of candidates and member law schools. Select from the list below to learn more about each offering.</p>
      
      <Link className="menu-item tile" href="/ambassadors"><span className="tile-text">LSAC Ambassadors</span></Link>      
      {/* <Link className="menu-item tile" href="/assessments"><span className="tile-text">Assessments</span></Link> */}      
      <Link className="menu-item tile" href="/DataResearch"><span className="tile-text">Research at LSAC</span></Link>
      <Link className="menu-item tile" href="/LawHub"><span className="tile-text">LawHub<sup>&trade;</sup></span></Link>
      <Link className="menu-item tile" href="/LawReady"><span className="tile-text">LawReady<sup>&trade;</sup></span></Link>
      <Link className="menu-item tile" href="/LEC"><span className="tile-text">LSAC Legal Education Consulting<sup>&#8480;</sup></span></Link>
      <Link className="menu-item tile" href="/LSAT"><span className="tile-text">LSAT<sup>&reg;</sup></span></Link>
      <Link className="menu-item tile" href="/MatriculationSurvey"><span className="tile-text">LSAC Matriculant Survey</span></Link>
      <Link className="menu-item tile" href="/PIF"><span className="tile-text">Professional Identity Formation</span></Link>
      <Link className="menu-item tile" href="/RiseAlliance"><span className="tile-text">RISE Alliance<sup>&reg;</sup></span></Link>
             
 </div>
   );
  };