import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import Link from "./Link";

/* Accessibility issues:
    - Need updated ARIA on the close transformation of the menu button
    - Need to lock focus into menu
    - Cannot scroll sidebar
*/

export default props => {
  return (
    
    <Menu>
      <Link className="menu-item" href="/ambassadors">LSAC Ambassadors</Link>
      
      {/* <a className="menu-item" href="/assessments">Assessments</a> */}
      
      <Link className="menu-item" href="/DataResearch">Research at LSAC</Link>
      <Link className="menu-item" href="/LawHub">LawHub<sup>&trade;</sup></Link>
      <Link className="menu-item" href="/LawReady">LawReady<sup>&trade;</sup></Link>
      <Link className="menu-item" href="/LEC">LSAC Legal Education Consulting<sup>&#8480;</sup></Link>
      <Link className="menu-item" href="/LSAT">LSAT<sup>&reg;</sup></Link>
      <Link className="menu-item" href="/MatriculationSurvey">Matriculation Survey</Link>
      <Link className="menu-item" href="/PIF">Professional Identity Formation</Link>
      <Link className="menu-item" href="/RiseAlliance">RISE Alliance<sup>&reg;</sup></Link>
    </Menu>
    
  );
};