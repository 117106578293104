import React from "react";

import Header from "./components/Header";
import Footer from 'components/Footer';
import Route from "./components/Route";
import Ambassadors from "./components/topics/Ambassadors";
import DataResearch from "./components/topics/DataResearch";
import LawHub from "./components/topics/LawHub";
import LawReady from "./components/topics/LawReady";
import LEC from "./components/topics/LEC";
import LSAT from "./components/topics/LSAT";
import MatriculationSurvey from "./components/topics/MatriculationSurvey";
import PIF from "./components/topics/PIF";
import RiseAlliance from "./components/topics/RiseAlliance";
import MoreInfo from "components/MoreInfo";
import FeedbackForm from "components/Feedback";
import Home from "components/Home";

import './App.css';
import './components/topics/css/mobile-phone.css';


//<link rel="manifest" href="/manifest.json"></link>

export default () => {


  return (
    <div className="App" id="outer-container page-wrap">
      <Header />
    <div className="ui container">
      
      <main className="main content">
      <Route path="/">
        <Home />
      </Route>
      <Route path="/ambassadors">
        <Ambassadors />
      </Route>
      <Route path="/DataResearch">
        <DataResearch />
      </Route>
      <Route path="/LawHub">
        <LawHub />
      </Route>
      <Route path="/LawReady">
        <LawReady />
      </Route>
      <Route path="/LEC">
        <LEC />
      </Route>
      <Route path="/LSAT">
        <LSAT />
      </Route>
      <Route path="/MatriculationSurvey">
        <MatriculationSurvey />
      </Route>
      <Route path="/PIF">
        <PIF />
      </Route>
      <Route path="/RiseAlliance">
        <RiseAlliance />
      </Route>
      <Route path="/MoreInfo">
        <MoreInfo />
      </Route>
      <Route path="/Feedback">
        <FeedbackForm />
      </Route>
      
      </main>      
    </div>
    <Footer />
    {/* {window.location.pathname != '/MoreInfo' && window.location.pathname != '/Feedback' && <Footer />}  */}
    </div>
  );
};