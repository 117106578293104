import React from 'react';



export default props => {
    return (
        <footer className="footer shadow top">            
            <a className="text-link" href="/Feedback">Give Feedback</a>
            <a className="main-btn" href="/MoreInfo">Request Information</a>
        </footer>
        
  );
};