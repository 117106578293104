import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/LEC.css';



export default props => {
    return (
        <div className="content lec">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">
                    
                        <h1>LSAC Legal Education Consulting<sup>&#8480;</sup></h1>
                         <p>Legal Education Consulting professionals know law schools inside and out. Our range of services are customized to meet your unique needs. Whatever your goals or challenges, we will help you
                            innovate and succeed.
                         </p>
                         <div className="text-block">
                            <ul>
                                <li>Enrollment and Marketing</li>
                                <li>Leadership and Strategy</li>
                                <li>Staffing Support</li>
                                <li>NextGen Evaluation</li>
                            </ul>
                        </div>
                </div>  
            {/* END OF SLIDE 1 */}
                <div className="board board2">
                    
                        <h1>What Our Clients Say</h1>
                        <p>From staffing support to enrollment modeling, curriculum review, and more, <span className="bold">LEC services</span> have helped law schools optimize how they work, meet challenges, and thrive.
                        </p>
                        <p className="quote">Having started this role mid-cycle with no staff and a high volume of pending
                            applications, I was ecstatic to learn of LSAC’s consulting services. <span className="bold">Legal Education
                            Consulting has become a true innovation partner for me</span> — analyzing and helping
                            us streamline our processes, counseling us on best practices, supporting the hiring of
                            new staff, and offering extensive training while helping us implement new features and
                            automation. The team hit the ground running alongside me and, <span className="bold">together, we are
                            building an admissions office of the future.</span> I could not be happier with my decision
                            to bring them on board.</p>
                        <p className="roxanna-cruz">&mdash; Roxanna Cruz, Associate Dean of Admissions, Ave Maria School of Law  
                        <span className="line-break">Read more at <a href="lsac.org/legal-education-consulting">lsac.org/legal-education-consulting</a>.</span></p> 
                </div> 
            {/* END OF SLIDE 2 */} 
                      
            </Carousel>
     
         </div>
    );
};