import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/PIF.css';



export default props => {
    return (
        <div className="content pif">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">
                    
                        <h1>Professional Identity Formation Program</h1>
                         
                         <div className="text-block">
                            <p>Explores core values and guiding principles including:</p>
                            <ul>
                                <li><span>Cultural responsiveness to clients</span></li>
                                <li><span>Obligations of anti-racism and access to justice</span> </li>
                                <li><span>Well-being practices</span></li>
                                <li><span>Commitment to ongoing professionally responsible practice and judgment</span></li>
                            </ul>
                        </div>
                        
                </div>  
            {/* END OF SLIDE 1 */}

            <div className="board board2">
                    
                    <h1>Professional Identity Formation Program</h1>
                     
                     <div className="text-blocks">
                        <div className="text-block1">Introduction to Professional Identity Development</div>
                        <div className="top-arrow"></div>
                        <div className="text-block2">Profession’s Values, Guiding Principles, and Well-being Practices</div>
                        <div className="bottom-arrow"></div>
                        <div className="text-block3">DEIB Context for Legal Profession and Education</div>
                        <div className="top-arrow"></div>
                        <div className="text-block4">Student’s Identity, Purpose, and Motivators</div>
                        <div className="bottom-arrow"></div>
                        <div className="text-block5">Student’s Integration of Professional and Personal Identities</div>
                    </div>
                    
            </div>  
              {/* END OF SLIDE 2 */}   

              <div className="board board3">
                    
                    <h1>Rigorous Integration Across the JD Program</h1>
                     
                     <div className="text-block">
                       
                        <ul>
                            <li><span>Flexible delivery of content from 0L to 3L</span></li>
                            <li><span>Rigorous review of knowledge retention and situational application</span> </li>
                            <li><span>Connections to curriculum, co-curriculum, and professional development</span></li>
                            <li><span>Downloadable student journal to map development of individual professional identity</span></li>
                        </ul>
                    </div>
                    
            </div>  
              {/* END OF SLIDE 3 */}                       
            </Carousel>
     
         </div>
    );
};