import React from 'react';
import Sidebar from "./Sidebar";
import Link from "./Link";
import logo from "./logo.png"

export default props => {
    return (
<header className="header shadow bottom">
<nav className="navbar">
<Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
</nav>
<div className="logo"><Link href="/"><img src={logo} alt="LSAC Logo" /></Link></div>



</header>
  );
};