export const Topics = [
    {
      id: "2",
      styleClass: "amb",
      name: "LSAC Ambassadors",
      value: "2"
    },
    {
      id: "1",
      styleClass: "dr",
      name: "Research at LSAC",
      value: "1"
    },
    {
      id: "3",
      styleClass: "lh",
      name: "LawHub",
      value: "3"
    },
    {
      id: "4",
      styleClass: "lr",
      name: "LawReady",
      value: "4"
    },
    {
      id: "5",
      styleClass: "lec",
      name: "LSAC Legal Education Consulting",
      value: "5"
    },
    {
      id: "6",
      styleClass: "lsat",
      name: "LSAT",
      value: "6"
    },
    {
      id: "7",
      styleClass: "mc",
      name: "Matriculation Survey",
      value: "7"
    },
    {
      id: "8",
      styleClass: "pif",
      name: "Professional Identity Formation",
      value: "8"
    },
    {
      id: "9",
      styleClass: "rise",
      name: "RISE Alliance",
      value: "9"
    }
  ];
  

