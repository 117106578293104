import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/LawReady.css';



export default props => {
    return (
        <div className="content lawready">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">                
                    <div className="text-block">
                         <h1>LawReady<sup>&trade;</sup></h1>
                            <p>Built upon leading research, LawReady enhances prelaw education and guidance that may already 
                                exist on campus and <span className="bold">works in collaboration with colleges and universities</span> to provide students 
                                with a roadmap to develop the crucial skills, abilities, and personal qualities needed to 
                                succeed in law school.</p>
                    </div>
                </div>
                {/* END OF SLIDE 1 */}
                    <div className="board board2">
                        <h1>LawReady<sup>&trade;</sup> For Law Schools</h1>
                        <div className="text-block1">
                            <p><span className="bold">LawReady</span> measures a broad range of skills, abilities, and personal characteristics based 
                                on LSAC’s research that links these traits to success in law school and the legal profession. </p>
                        </div>
                        <div className="text-block2">
                        <p><span className="bold">LawReady assessments</span> offer valuable information about applicant skills related to law school success. 
                                The LawReady certificate, if earned, indicates that a student has demonstrated skills that meet or 
                                exceed law faculty expectations for what beginning law students should be able to do. </p>
                        </div>
                        <div className="text-block3">
                            <p>The <span className="bold">LawReady portfolio</span> and <span className="bold">LawReady certificate</span>, if earned, will include detailed information about 
                                student participation and performance in the program, offering deeper context into the body of academic 
                                work included in the undergraduate transcript.</p>
                        </div>
                    </div>
                 {/* END OF SLIDE 2 */}       
                    <div className="board board3">
                        <h1>LawReady<sup>&trade;</sup> Reporting</h1>
                        <div className="text-blocks">
                        <div className="text-block1">
                            <h2>LawReady portfolio</h2>
                            <ul>
                                <li>Unlocks the power of undergraduate coursework</li>
                                <li>Includes high-quality evidence of a student's work and experiences</li>
                                <li>Helps law schools better understand what was covered and learned in classes</li>
                            </ul>
                        </div>
                        <div className="text-block2">
                            <h2>LawReady certificate</h2>
                            <ul>
                                <li>Awarded to students that demonstrate proficiency in each of the LawReady <span className="bold">Skills, Abilities and Personal Qualities (SAQs)</span></li>
                                <li>The LawReady Certificate is not yet a standalone credential &mdash; and like the LSAT, will be interpreted by each law school</li>
                            </ul>                            
                        </div>
                        <div className="text-block3">
                            <h2>Student dashboard</h2>
                            <ul>
                                <li>Reflects the whole picture &mdash; multiple dimensions of readiness across the academic, navigation, and engagement pillars of the program</li>
                                <li>Tracks students' progress over time, awarding badges for each module they complete</li>
                                <li>Shared with prelaw advisors to provide visibility into student progress and opportunities</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                {/* END OF SLIDE 1 */}     
            </Carousel>
     
         </div>
    );
};