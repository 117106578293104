import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/Ambassadors.css';



export default props => {
    return (
        <div className="content ambassadors">            
            <Carousel showThumbs={false} dynamicHeight={true}>
            <div className="board board1">
                <div className="text-block">
                        <h1>LSAC Ambassadors</h1>
                        <p className="tagline">Collaborators in Law School Admission Success</p>                
                    
                        <p>LSAC Ambassadors are law school admission experts committed to advancing LSAC's mission of increasing diversity, equity, and inclusion in legal education.</p>
                        <p>Ambassadors support excellence and success for legal education professionals. Through consultative visits with admission and other professional staff, they ...</p>      
                        <p className="tags"><span className="bold">elevate, enhance,</span> and <span className="bold">optimize.</span></p>
                    </div>
                </div>  
            {/* END OF SLIDE 1 */}     
                <div className="board board2">
                    <div className="logo-block">
                        <h1>LSAC Ambassadors</h1>
                    </div>
                    <div className="text-blocks">
                    <div className="text-block1">
                        <h2>Elevate...</h2>  
                        <p>awareness of and proficiency in using LSAC's valuable array of tools, information, and resources to help the admission office run efficiently and effectively.</p>      
                    </div>

                    <div className="text-block2">
                        <h2>Enhance...</h2>  
                        <p>knowledge and application of admission best practices and other matters to ensure fairness and equity for all.</p>      
                    </div>

                    <div className="text-block3">
                        <h2>Optimize...</h2>  
                        <p>law school recruiting efforts by participating in recruiting events to inform, encourage, and inspire prospective applicants.</p>      
                    </div>  
                    </div>     
                </div> 
            {/* END OF SLIDE 2 */} 
                 <div className="board board3">
                    <div className="text-block1">
                        <h1>LSAC Ambassadors Can Support and Enhance Law School Outreach</h1>
                        <p>Ambassadors are pleased to support admission office recruitment and outreach. They engage with prospective applicants through events and presentations. Presentation topics include:</p>
                        <ul>
                            <li>Preparing for Success in Your Law School Journey</li>
                            <li>Financing Your Legal Education</li>
                            <li>How to Apply to Law School With LSAC's Credential Assembly Service</li>
                            <li>Law School Offers: How to Make the Best Choice</li>
                            <li>LSAC Account Walk-Through</li>
                            <li>Preparing for the LSAT</li>
                        </ul>
                    </div>
                    
                     <div className="text-block2">
                        <a href="mailto:lawschoolengagement@lsac.org" class="sr-only">Send an email to request a visit</a>
                        <p>Scan the QR code to request a visit.</p>      
                    </div>
                    <div className="text-block3">
                        <p>Contact the Ambassadors today at <a className="bold" href="mailto:lawschoolengagement@LSAC.org">lawschoolengagement@LSAC.org</a></p>
                    </div>
                </div> 
                {/* END OF SLIDE 3 */}
                     
            </Carousel>
     
         </div>
    );
};