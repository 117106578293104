import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/Rise.css';



export default props => {
    return (
        <div className="content rise">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">
                    
                        <h1>RISE Alliance<sup>&reg;</sup></h1>
                        <p className="tagline">Building a Strong Foundation for an Inclusive Future of Justice</p>
                        <p>
                        <span className="bold">RISE Alliance (Reaffirming Individuals Supporting Education)</span> is a national center within LSAC designed to support the 
                        diversity, equity, inclusion, and belonging needs of student-facing professional law school staff on the front line — 
                        people in law school admission and financial aid, diversity, and student services roles. 
                        </p>
                    
                    
                </div>  
            {/* END OF SLIDE 1 */}
                <div className="board board2">
                    
                        <h1>RISE Alliance<sup>&reg;</sup></h1>
                        <p className="tagline">Creating a Community of Empowerment and Support</p>
                        <p>Empowered, diverse law school staff members add an important perspective to the work of legal education, helping to build a future of justice that represents a vibrant, diverse society.</p>
                        
                        <div className="text-blocks">
                        <h2 className="p1">RISE Alliance serves as a strategic collaborator to:</h2>
                        <p className="p2"><span className="bold">Coach</span> and <span className="bold">assist</span> people, especially members of minoritized groups, to advocate for themselves and ensure their voices are heard and valued.</p>
                        <p className="p3"><span className="bold">Identify, establish,</span> and <span className="bold">facilitate</span> ongoing programming to advance culturally competent, inclusive leadership and professional development.</p>
                        <p className="p4"><span className="bold">Support</span> identity and affinity groups to strengthen their engagement with each other and with LSAC.</p>
                        <p className="p5"><span className="bold">Develop</span> and <span className="bold">share</span> best practices in support of the professional success of minoritized staff and help to create a culture of belonging.</p>
                        <p className="p6"><span className="bold">Enhance</span> awareness of the value of communicating diversity, equity, inclusion, and belonging in building community.</p>
                        </div>
                        <div className="jay-austin"><p><span className="bold">RISE Alliance</span> is led by <span className="bold">Jay Austin</span>, one of the country's most respected former admission deans and a leading voice in intersectional community building.</p> <p>Contact <span className="bold">RISE Alliance</span> at <a className="bold" href="mailto:RISEAlliance@LSAC.org">RISEAlliance@LSAC.org</a></p> </div>  

                </div> 
            {/* END OF SLIDE 2 */} 
                      
            </Carousel>
     
         </div>
    );
};