import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/LawHub.css';



export default props => {
    return (
        <div className="content lawhub">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">
                    <div className="logo-block">
                        <h1>LawHub<sub>&trade;</sub></h1>
                        <p className="tagline">Powered by LSAC<sup>&reg;</sup></p>
                    </div>
                    <div className="text-block">
                        <h2>Explore. Prepare. Succeed.</h2>  
                        <p className="goal">LawHub supports students on their learning journeys with short, accessible courses designed to help them feel prepared for law school on day one, including <span className="bold">Law School Unmasked, Law School Jumpstart, and Legal Analysis Boot Camp</span>.</p>      
                    </div>
                </div>  
            {/* END OF SLIDE 1 */}
                <div className="board board2">
                    <div className="logo-block">
                        <h1>LawHub<sub>&trade;</sub></h1>
                    </div>
                    
                    <div className="quote">
                        <p><span className="stylized">"</span>I feel much more prepared and less overwhelmed going into my first day. I would recommend this to everyone starting law school to just have a better understanding of what is going to be expected."</p>      
                        <p>&mdash; Law School Unmasked participant</p>
                    </div>
                    <div className="text-blocks">
                    <div className="text-block1">
                        <h2>Law School Unmasked</h2>  
                        <p>An introduction to the most important features of legal education and the law school classroom. As with all of our courses, there is an intentional focus on helping students cultivate a sense of belonging in law school.</p>      
                    </div>

                    <div className="text-block2">
                        <h2>Law School Jumpstart</h2>  
                        <p>In-depth instruction on case-briefing, note-taking, outlining, and more, all situated in a series of interactive mock torts classes.</p>      
                    </div>

                    <div className="text-block3">
                        <h2>Legal Analysis Boot Camp</h2>  
                        <p>A step-by-step guide to working through a legal analysis problem, with opportunities to practice essay-writing and self-assess with a sample answer.</p>      
                    </div> 
                    </div>      
                </div> 
            {/* END OF SLIDE 2 */} 
                 <div className="board board3">
                    <div className="text-block">
                        <h1>Designed for Student Impact</h1>
                        <p>These programs have demonstrated impact on students. For example, upon completing <span className="bold">Law School Jumpstart:</span></p>
                    </div>
                    <div className="text-blocks">
                    <div className="text-block1">
                       <p><span className="percentage">73%</span>of participants reported they feel confident preparing for a class that uses the Socratic method.</p>      
                    </div>

                    <div className="text-block2">
                    <p><span className="percentage">81%</span>of participants feel confident about their ability to perform well in law school.</p>      
                    </div>

                    <div className="text-block3">
                    <p><span className="percentage">73%</span>of participants said they know how to prepare for law school exams.</p>      
                    </div> 
                    </div>      
                </div>      
            </Carousel>
     
         </div>
    );
};