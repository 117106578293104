import React, { useState} from "react";
import TopicForm from "./TopicForm";   

// PowerAutomate URL from step 1 HTTP Request goes here
// DEV LINK
//const FORM_ENDPOINT = "https://prod-77.westus.logic.azure.com:443/workflows/5403e37503b34a0aa594805c95095e33/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uBiIRKqyPneUMRGjqGU36bCJACQZN9SAKyKaRooVd_E";

//PRODUCTION LINK
const FORM_ENDPOINT = "https://prod-156.westus.logic.azure.com:443/workflows/241c6344515e4a29a3b0da91d247ebde/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pSftWHIgrm2nhbhmYZa-3-pa9aILK71CeU13V13mtM0";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {topics:[]}; //Sends topics as array
    //console.log('body: ',JSON.stringify(data))
    console.log('Logging working!')
    let topicChk = false;
    let contents = document.getElementById('errorMsg');
    console.log('Contents:', contents)
   
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        if (inputs[i].type==="checkbox" && inputs[i].checked) {
          data.topics.push(inputs[i].value); //Sends topics as array 
          if (topicChk == false) {
            topicChk = true;
          }  
          contents.innerHTML = '';       
        }
        if (inputs[i].type!=="checkbox"){
          data[inputs[i].name] = inputs[i].value;
        }                
      }   
    }
   // console.log('body: ',JSON.stringify(data))
  

  if (topicChk) {  
    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Form response was not ok');
        }

        setSubmitted(true);
      })
      .catch((err) => {
        // Submit the form manually
        e.target.submit();
      });
    } 
    else {
      contents.innerHTML = '<p>Please select at least 1 topic above.</p>'
    }
  };
  

  if (submitted) {
    return (
      <>
        <h2 className="thankYou">Thank you for submitting your information!</h2>
        <div><p>We hope you enjoyed your experience and learned more about the many ways LSAC can support 
          you and your school's success. More information on the offering(s) you selected will be emailed 
          to you at the address you provided on this form. We look forward to working together to advance 
          law and justice in the new year. </p>

        <p>Visit us anytime at <a href="LSAC.org">LSAC.org</a>.</p></div>  

        
        <h3 className="thankYou">Resources That Support Your Work</h3>
        <p>In the meantime, you can select the below links to access overviews (PDF format) of LSAC’s portfolio of offerings and learn more about which products and services can best support you in your work.</p>
        <div className="solution-sheets">
        <a className="menu-item tile" href="https://www.lsac.org/document-library/572" target="_blank" rel="noreferrer"><span className="tile-text">LSAC Law School Engagement and Ambassador Teams</span></a>
        <a className="menu-item tile" href="https://www.lsac.org/document-library/530" target="_blank" rel="noreferrer"><span className="tile-text">LawHub<sup>&trade;</sup></span></a>
        <a className="menu-item tile" href="https://www.lsac.org/document-library/529" target="_blank" rel="noreferrer"><span className="tile-text">LawReady<sup>&trade;</sup> (for Law Schools)</span></a>
        <a className="menu-item tile" href="https://www.lsac.org/document-library/516" target="_blank" rel="noreferrer"><span className="tile-text">LSAC Legal Education Consulting<sup>&#8480;</sup></span></a>
        <a className="menu-item tile" href="https://www.lsac.org/document-library/404" target="_blank" rel="noreferrer"><span className="tile-text">LSAC and the LSAT<sup>&reg;</sup></span></a>
        <a className="menu-item tile" href="https://www.lsac.org/document-library/514" target="_blank" rel="noreferrer"><span className="tile-text">Professional Identity Formation</span></a>
        <a className="menu-item tile" href="https://www.lsac.org/document-library/515" target="_blank" rel="noreferrer"><span className="tile-text">RISE Alliance<sup>&trade;</sup></span></a>

        </div>       
      </>
    );
  }
 

return (
    <div className="content form">
        <h1>Request More Information</h1>
        <p>Interested in receiving more information? Select which offering(s) you'd like to explore further and complete the form below to have additional details delivered to your inbox.</p>
   
       <form
        action={FORM_ENDPOINT}
        onSubmit={handleSubmit}
        method="POST"
        encType="application/json"
        className="contactForm">   
        <div>
         
         <fieldset>
          <legend>Topic(s) of Interest (Select as many as you'd like.  At least 1 selection is required.)</legend>
            <TopicForm />
               
          </fieldset>
          
        </div>
      <div>
        <label className="textLabel" htmlFor="firstName">First/Preferred Name (required)</label>
        <input type="text" name="firstname" id="firstName" autoComplete="given-name" required />
      </div>
      <div>
        <label className="textLabel" htmlFor="lastName">Last Name (required)</label>
        <input type="text" name="lastname" id="lastName" autoComplete="family-name" required />
      </div>
      <div>
        <label className="textLabel" htmlFor="email">Email Address (required)</label>
        <input type="email" name="email" id="email" autoComplete="email" required />
      </div>
       <div>
        <label className="textLabel" htmlFor="school">School</label>
        <input type="text" name="school" id="school" autoComplete="off" />
      </div>
      <div>
        <label className="textLabel" htmlFor="title">Title</label>
        <input type="text" name="title" id="title" autoComplete="off" />
      </div>
      
      <div>
      <button type="submit">Submit Your Request</button>
      <div className="errorMsg" id="errorMsg"></div>
      </div>
      </form>
    </div>

       
    );
  };
  export default ContactForm;