import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/matriculation.css';



export default props => {
    return (
        <div className="content matriculation">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">
                    
                         <div className="text-block">
                         <h1>LSAC Matriculant Survey</h1>
                            <p>Each year, LSAC administers the Matriculant Survey to a random sample of first-year law students. 
                                The purpose of the survey is to understand who current law school matriculants are and what factors 
                                they considered to determine which law school(s) would meet their academic, professional, and 
                                personal needs. </p>
                            <p>Results from the survey inform how LSAC’s products 
                                and services can be developed to best support individuals throughout their learning journeys. This 
                                information also helps LSAC engage and support law schools’ efforts toward equitable and inclusive 
                                recruitment strategies and admission outcomes.</p>
                        </div>
                </div>  
            {/* END OF SLIDE 1 */}
                <div className="board board2">
                <div className="text-block1">
                    <h1>Learning From 2022 Matriculants</h1>
                         
                            <p>According to data gathered from LSAC’s 2022 Matriculant Survey, first-year law students with 
                                marginalized identities and from underserved communities first considered attending law school 
                                at an earlier age than their peers.</p>
                         
                        </div>
                        <div className="text-block2">
                            <p><span className="bold">26% of Black respondents</span> said they first considered attending law school in elementary school, while only 12% of white and 6% of Asian respondents agreed.</p>
                        </div>
                        <div className="text-block3">
                            <p>Interestingly, the distribution for first-generation college respondents 
                                vs. continuing-generation college respondents looked similar, 
                                with <span className="bold">18% of first-generation respondents</span> considering attending 
                                law school in elementary school compared to 11% of their continuing-generation counterparts. </p>
                        </div>
                    </div>
            {/* END OF SLIDE 2 */} 
                      
            </Carousel>
     
         </div>
    );
};