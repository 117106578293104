import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './css/data-research.css';



export default props => {
    return (
        <div className="content data-research">            
            <Carousel showThumbs={false} dynamicHeight={true}>
                      
                <div className="board board1">
                    <div className="text-block">
                        <h1>Research at LSAC</h1>
                          <p>Research at LSAC is the premier destination for research to inform and improve legal education. 
                                We use <span className="bold">applied social science</span> to inform equity-driven improvements to legal education.</p>
                    </div>                        
                </div>  
            {/* END OF SLIDE 1 */}
            <div className="board board2">
               <div className="text-block">
                     <h1>What Research at LSAC can do for you &hellip;</h1>
                        <p>Research at LSAC offers the most <span className="bold">expansive collection of data</span> on law school applicants and matriculants … anywhere. </p>
                        <p>Research at LSAC can help your school with <span className="bold">grant writing and reporting, data analytics, surveys</span> (design, review, and administration), and <span className="bold">program evaluation</span>.</p>
                </div>                    
            </div>  
        {/* END OF SLIDE 2 */} 
{/* 
        <div className="board board3">
                    
                        <h1>How do students understand the application process?</h1>
                        <h2>Research at LSAC reveals:</h2> 
                         <div className="text-block1">
                            <p>About <span className="bold">1 in 2</span> of 2023 LawHub Admission Unmasked enrollees did not know what an admissions committee is looking for in a law school application.</p>
                        </div>
                        <div className="text-block2">
                            <p><span className="bold">1 in 2</span> LawHub Admission Unmasked enrollees were not confident that they knew how to choose the right law school for them.</p>
                        </div>
                        <div className="footnote">
                            <p>(Source: 2023 LawHub Admission Unmasked Pre-Survey. n=1,446)</p>
                        </div>
                        
                </div>  
            END OF SLIDE 3 */}   

            <div className="board board4">
                    
                        <h1>When do people think about going to law school?</h1>
                        <h2>Research at LSAC reveals:</h2> 
                         <div className="text-block1">
                            <p>More than <span className="bold">1 in 2 prospective law school applicants</span> first think about going to law school before college.</p>
                        </div>
                        <div className="text-block2">
                            <p>About <span className="bold">1 in 4 prospective law school applicants</span> first think about going to law school when they are in high school.</p>
                        </div>
                        <div className="footnote">
                            <p>(Source: 2023 August through November Post-LSAT Questionnaire. n=14,679)</p>
                        </div>
                        
                </div>  
            {/* END OF SLIDE 4 */}
           <div className="board board5">
                    
                        <h1>Why do people want to go to law school?</h1>
                        <h2>Research at LSAC reveals:</h2> 
                         <div className="text-block1">
                         <span className="bold">Top motivations for going to law school: </span> 
                            <ul>
                                <li>to help others</li>
                                <li>to develop valuable skills</li>
                                <li>to advocate for social justice</li>                            
                            </ul>    
                        </div>
                        <div className="text-block2">
                            <p>About <span className="bold">1 in 3</span> prospective law school applicants report their top motivating reason for going to law school is to advocate for social justice.  </p>
                        </div>
                        <div className="footnote">
                            <p>(Source: 2023 August through November Post-LSAT Questionnaire. n=14,679)</p>
                        </div>
                        
                </div>  
            {/* END OF SLIDE 5 */}
            <div className="board board6">
                    
                        <h1>What advice would 1Ls give to future applicants?</h1>
                        <h2>Research at LSAC reveals:</h2> 
                        <div className="text-blocks">
                         <div className="text-block1">
                            <p><span className="bold break">Law school reputation</span> was overall the most important factor for 2022 1Ls in deciding where to go to law school. </p>   
                            <p>When asked to offer advice to future law school applicants, <span className="bold">law school reputation was only the third most important factor </span>they listed. </p>
                        </div>
                        <div className="text-block2">
                            <p><span className="bold break">Financial aid</span> was the most important factor 1Ls thought future law school applicants should consider when deciding where to enroll. </p>
                        </div>
                        <div className="text-block3">
                            <p><span className="bold break">Academic support</span> was important to all law students, but it was a significant concern for 1Ls from underrepresented communities.*</p>
                        </div>
                        </div>
                        <div className="footnote">
                            <p>*In a 2022 survey of 1Ls, LSAC fee waiver recipients were 30% more likely and first-generation college graduates were 20% more likely to advise that future applicants consider academic support as a determining factor when choosing a law school. </p>
                        </div>
                        
                </div>  
            {/* END OF SLIDE 6 */}
            <div className="board board7">
                    
                        <h1>Equity-Informed Research Matters</h1>
                         <div className="text-block1">
                            <p><span className="bold">Challenge: 47% of 0L students</span> reported not being familiar with the law school curriculum at the beginning of the 2023 Law School Unmasked program. </p>   
                            <p className="stylized">Contact Legal Education Consulting to learn what Research at LSAC can do for you. </p>
                        </div>
                        <div className="text-block2">
                            <ul>
                                <li><span className="bold">Strategy:</span> Understanding who comprises the 47% and meeting their needs. </li>
                                <li><span className="bold">Finding:</span> Black/African American and Hispanic/Latin&eacute; respondents are overrepresented in the 47%.</li> 
                                <li><span className="bold">Solution:</span> Develop a programmatic approach to support these students.</li>  
                                <li><span className="bold">Outcome:</span> Target populations become more confident, knowledgeable, and positioned to succeed in law school.</li>
                            </ul>
                        </div>
                        
                        <div className="footnote">
                            <p>(Source: 2023 LawHub Law School Unmasked Pre-Survey. n= 1,504)</p>
                        </div>
                        
                </div>  
            {/* END OF SLIDE 7 */}
            </Carousel>
     
         </div>
    );
};