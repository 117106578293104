import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox";
import { Topics } from "./Topics";


const TopicForm = () => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  

  useEffect(() => {
    setList(Topics);
  }, [list]);

  
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map(li => li.value));
    if (isCheckAll) {
      setIsCheck([0]);
    }
  };

  const handleClick = e => {
    const { value, checked } = e.target;
    setIsCheck([...isCheck, value]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== value));
    }
  };

   // console.log(isCheck);

  const catalog = list.map(({ id, name, value, styleClass }) => {
    return (
      <>
        <div className={'topics ' + styleClass} key={id}>
          <Checkbox
          key={id}
          type="checkbox"
          name={name}
          value={value}
          id={id}
          handleClick={handleClick}
          isChecked={isCheck.includes(value)}
          
        />
        <label htmlFor={id}><span className="text">{name}</span></label>
        </div>
        </>
    );
  });

  return (
    <>
     {catalog}
     <div className="topics selectAll" key="selectAll">
      <Checkbox
        type="checkbox"
        name="selectAll"
        id="selectAll"
        key="selectAll"
        value="0"
        handleClick={handleSelectAll}
        isChecked={isCheckAll}
      />
      
      <label htmlFor="selectAll"><span className="text">Select All</span></label>
      </div>
      
    </>
  );
};

export default TopicForm;